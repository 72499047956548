import { EOLErrorCodes } from "app/common/errors";
import ResponseError from "app/common/responseError";
import { Guides, Intro, LatestNews, Tools, Vault, WorkshopsListing } from "app/components/templates/HomePage";
import { useErrorHandler } from "app/hooks/useErrorHandler";
import { useFeatureConfig } from "app/hooks/useFeatureConfig";
import { useGlobalLanguageState } from "app/utils/internationalization/GlobalLanguageProvider";
import { useEffect } from "react";
import "./index.scss";

const Index = (): JSX.Element => {
	const { sendError } = useErrorHandler();
	const [language] = useGlobalLanguageState();
	const { featureConfig } = useFeatureConfig();
	const { SHOW_LATEST_NEWS } = featureConfig;

	useEffect(() => {
		if (window.location.search && window.location.search === "?auth_error=true") {
			sendError(new ResponseError(EOLErrorCodes.AuthError, ""));
		}
	}, [window.location.search]);

	return (
		<div className="page">
			<Intro language={language} />
			<Guides language={language} />
			<Vault language={language} />
			<Tools language={language} />
			{SHOW_LATEST_NEWS && <LatestNews />}
			<WorkshopsListing language={language} />
			<div className="mt64" />
		</div>
	);
};

export default Index;
