import { WebviewHelper, deleteCookie } from "@wog/mol-web-common";
import { TypesOfCookies } from "app/common/constant/cookies";
import Router from "next/router";
import parse from "url-parse";

interface IUrl {
	hash: string;
	query: { [key: string]: string };
}

// Add other domain if there is any, the only use in mylegacy is to redirect users to mylegacy
// auth redirect is handled on authforwarder
export const allowedDomains = (url: string): boolean => {
	try {
		const { hostname } = new URL(url);
		const currentDomain = window.location.hostname;
		return hostname === currentDomain || hostname === "localhost";
	} catch {
		return true;
	}
};

export const withAuthRedirect = (redirectURL: string, forcedAuth = false): string => {
	const forcedAuthQuery = forcedAuth ? "&forcedAuth=true" : "";
	const isLifeSgAppQuery = WebviewHelper.isLifeSgApp() ? "&webview=true" : "";
	return `/auth?redirect=${redirectURL}${forcedAuthQuery}${isLifeSgAppQuery}`;
};

export const setLogout = (): void => {
	void Router.push(`/auth/logout${WebviewHelper.isLifeSgApp() ? "?isLifeSgApp=true" : ""}`)
		.then(() => {
			sessionStorage.clear();
			// [MOL-14010][GW] Expiry cookie was not deleted in safari browser, causing an infinite redirect loop
			deleteCookie({ name: TypesOfCookies.EXPIRY_KEY, path: "/" });
		})
		.catch((err) => {});
};

export const useURL = (): IUrl => {
	const hash: string = window ? window.location.hash.substring(1) : "";
	const queryString: string = window ? window.location.search.substring(1) : "";

	const query = {};

	queryString.split("&").forEach((queryKeyValue: string) => {
		const separatedQuery: string[] = queryKeyValue.split("=");

		if (separatedQuery.length === 2) {
			const [queryKey, queryValue] = separatedQuery;
			query[queryKey] = queryValue;
		}
	});

	return { hash, query };
};

export const getId = (): string => {
	// financial-accounts/bank-account?id=:id
	// financial-accounts/bank-account/edit?id=:id
	let id = "";
	const path = window.location.pathname;
	const pathArray = path.split("/").filter((value: string) => {
		return value !== "";
	});
	const action = pathArray[pathArray.length - 1];

	if (action === "edit") {
		id = pathArray[pathArray.length - 2];
	} else {
		id = action;
	}

	return id;
};

export const getNotificationId = (): string => {
	// notifications?id=:id
	// notifications/edit?id=:id
	const path = window.location.pathname;
	const pathArray = path.split("/").filter((value: string) => {
		return value !== "";
	});
	const id = pathArray[pathArray.length - 1];

	if (id !== "notifications") {
		return id;
	}

	return "";
};

export const getInvitationId = (positionFromEnd?: number) => {
	let id = "";
	const path = window.location.pathname;
	const pathArray = path.split("/").filter((value: string) => {
		return value !== "";
	});

	let indexToReturn = pathArray.length - 1;
	if (undefined !== positionFromEnd) {
		indexToReturn = pathArray.length - positionFromEnd;
	}
	const idWithName = pathArray[indexToReturn];
	id = idWithName.split("-")[0];
	id = id.toUpperCase();
	return id;
};

export const scrollToAnchor = (): void => {
	if (typeof window !== "undefined") {
		const { hash } = useURL();
		if (!hash) {
			return;
		}

		setTimeout(() => {
			const element = document.getElementById(hash);
			if (element) {
				element.scrollIntoView();
			}
		}, 1000);
	}
};

export const cleanRedirectURL = (path: string): string => {
	const url = parse(path, true);

	const basePath = url.origin + url.pathname;
	const query: string[] = [];
	const queryObj: { [key: string]: string } = url.query;

	Object.entries(queryObj).map(([key, value]) => {
		/**
		 * if query value contains http://
		 * encode the query string of the value
		 * then encode the whole Url
		 */
		if (/^http:\/\//.test(value)) {
			const innerUrl = parse(value);
			const toEncode = value.replace(`${innerUrl.origin}${innerUrl.pathname}`, "");
			const fixUrl = innerUrl.origin + innerUrl.pathname + encodeURIComponent(toEncode);
			query.push(`${key}=${encodeURIComponent(fixUrl)}`);
		} else {
			query.push(`${key}=${encodeURIComponent(parse(value).href)}`);
		}
	});

	const queryString = query.length > 0 ? "?" + query.join("&") : "";

	return basePath + queryString;
};
