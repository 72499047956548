import { Toggle, ToggleProps } from "@lifesg/react-design-system/toggle";
import { IFormInputProps, withBaseInput } from "app/hooks/useForm/components/Form.Input";
import { useEffect, useState } from "react";
import { CompositeOptionErrorMessage } from "./styles";

interface ICompositeOptionOrigProps extends IFormInputProps {
	compositeOptionTitle: React.ReactNode;
	compositeOptionSubtitle?: string | JSX.Element;
	children?: React.ReactNode;
	showChildren?: boolean;
	onRemove?: (() => void) | undefined;
	errorList?: string[] | JSX.Element | undefined;
	remove?: boolean | undefined;
	collapsible?: boolean;
	checked?: boolean;
	error?: boolean;
	errorMessage?: string;
	onClick?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
	className?: string;
	expandable?: boolean;
}

interface IFormCompositeOptions extends ICompositeOptionOrigProps, Omit<ToggleProps, "title" | "children" | "onClick"> {
	origProps?: ICompositeOptionOrigProps;
	onFocus?: () => void;
}

const FormCompositeOption = withBaseInput<IFormCompositeOptions>(
	({ origProps }: IFormCompositeOptions): JSX.Element => {
		if (!origProps) {
			return <></>;
		}

		const {
			compositeOptionSubtitle,
			compositeOptionTitle,
			children,
			expandable = true,
			showChildren,
			remove,
			collapsible,
			errorList,
			onRemove,
			checked,
			error,
			errorMessage,
			onClick,
			className,
			...otherProps
		} = origProps;
		const [selected, setSelected] = useState(checked);
		useEffect(() => {
			setSelected(checked);
		}, [checked]);

		const withoutRemove = selected
			? {
					collapsible: collapsible,
					children: children,
					initialExpanded: showChildren,
					errors: errorList,
			  }
			: undefined;

		const withRemove = {
			collapsible: collapsible,
			children: children,
			initialExpanded: showChildren,
			errors: errorList,
		};

		return (
			<>
				<Toggle
					{...otherProps}
					error={error}
					indicator={true}
					subLabel={compositeOptionSubtitle}
					id={origProps.id}
					removable={remove}
					checked={selected}
					onRemove={() => {
						if (onRemove) {
							onRemove();
						}
					}}
					compositeSection={expandable ? (remove ? withRemove : withoutRemove) : undefined}
					onChange={(e) => {
						setSelected(!selected);
						if (onClick) {
							onClick(e);
						}
					}}
					className={`${className || ""} fill`}
				>
					{compositeOptionTitle}
				</Toggle>
				{errorMessage && (
					<CompositeOptionErrorMessage
						id={`composite-option-error-message`}
						weight="semibold"
						tabIndex={0}
						data-testid={`error-message`}
					>
						{errorMessage}
					</CompositeOptionErrorMessage>
				)}
			</>
		);
	},
);

export default FormCompositeOption;
