import Translation404 from "app/assets/files/key-value-translations/error404.json";
import { AcpUrl } from "app/modules/acp/constants";
import { LpaOrAcpUrl } from "app/modules/lpa-or-acp/constants";
import { hdbParkingUrl } from "app/modules/hdb-car-parking/constants";
import { LpsLandingPage } from "app/modules/life-planning-service/constants";
import { NodUrl } from "app/modules/notice-of-death/constants";

export enum AvailableLanguages {
	zh = "zh",
	ml = "ml",
	ta = "ta",
	en = "en",
}

export const LanguageISOCode = {
	[AvailableLanguages.en]: "en",
	[AvailableLanguages.ml]: "ms",
	[AvailableLanguages.zh]: "zh-CN",
	[AvailableLanguages.ta]: "ta",
};
/**
 * List patterns of pages that do not have translation.
 *
 * It checks from the start of the path, and will match partially unless specified in the regex.
 * Example: Rule `/find-a-service` will match:
 *  - '/find-a-service'
 *  - '/find-a-service/find-advance-care-plan-facilitator'
 *
 * To match only exactly `/find-a-service` and none of the children, the pattern needs to be
 * regex: '/find-a-service/?$' (regex with optional slash and nothing else afterwards)
 */
const pagesWithoutTranslation: string[] = [
	"/vault",
	"/contact",
	"/privacy-statement",
	"/terms-of-use",
	"/find-a-service/?$", // only root page for `find-a-service`
	"/find-a-service/find-advance-care-plan-facilitator",
	"/find-a-service/find-lpa-acp-bundled-provider",
	"/find-a-service/find-hospice",
	"/find-a-service/find-home-nursing-care-services",
	"/find-a-service/find-respite-care-provider",
	"/find-a-service/find-home-care-equipment-and-assistive-devices",
	"/find-a-service/find-funeral-director",
	"/find-a-service/find-town-council",
	"/find-a-service/find-police-post",
	"/find-a-service/find-counselling-services",
	"/find-a-tool/learning-tool-write-a-will",
	"/make-your-lpa-acp",
	"/death-or-stillbirth-certificate",
	"/find-a-service/lpa",
	LpsLandingPage,
	"/results",
	"/find-a-tool",
	hdbParkingUrl.landingPage,
	AcpUrl.landingPage,
	LpaOrAcpUrl.landingPage,
	NodUrl.landingPage,
];

/**
 * check if page should be translated
 * @param currentPath
 * @param error?
 */

export const checkIfPageHasTranslation = (currentPath: string, error?: boolean): boolean => {
	const pageTitles: string[] = [];
	Object.keys(Translation404).forEach((lang) => {
		pageTitles.push(Translation404[lang as AvailableLanguages].PageTitle.data);
	});
	const title = document.title.split(" - ")[0];
	// check error in 404 or 500 pages in vault
	if (pageTitles.includes(title) || error) {
		return true;
	}

	// if any rule from `pagesWithoutTranslation` applies, there is no translation
	const hasNoTranslation = pagesWithoutTranslation.some((noTranslation) =>
		new RegExp(noTranslation, "y").test(currentPath),
	);
	return !hasNoTranslation;
};
