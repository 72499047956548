import { MagnifierIcon } from "@lifesg/react-icons/magnifier";
import "./SearchbarLabel.scss";

interface IProps {
	isDesktop?: boolean;
	isSelected: boolean;
	title: string;
}

const SearchbarLabel = (props: IProps): JSX.Element => {
	return (
		<>
			<div className="searchbar-label">
				<MagnifierIcon className="searchbar-label__icon" />
				{props.isDesktop && <div className="searchbar-label__text">{props.title}</div>}
			</div>
			{props.isDesktop && props.isSelected && <div className="searchbar-indicator"></div>}
		</>
	);
};

export default SearchbarLabel;
