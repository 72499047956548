import { IsDate } from "app/common/validators";
import { Transform } from "class-transformer";
import { IsBoolean, IsEnum, IsOptional, IsString } from "class-validator";
import { IsLocalPhoneNumber, IsNRIC } from "../../../validators";
import { UNSAFE_toJsDate } from "../../transformers";
import { PreferenceType } from "../enums";
import { IsEmail } from "@wog/mol-lib-api-contract/utils/validators";
import { EMAIL_MAX_CHARS } from "app/common/email";

class BaseUserDO {
	@IsOptional()
	@IsString()
	public name!: string;
	@IsNRIC()
	public nric!: string;
	@IsOptional()
	@IsLocalPhoneNumber()
	public phone?: string | null;
	@IsEmail({ maxLength: EMAIL_MAX_CHARS })
	@IsOptional()
	public email?: string | null;
	@IsOptional()
	@IsDate()
	@Transform(UNSAFE_toJsDate)
	public dateOfBirth?: Date | null;
}

export class UpdateUserResponseDO extends BaseUserDO {
	@IsBoolean()
	public isOnboarded!: boolean;
}

export class GetUserResponseDO extends BaseUserDO {
	@IsOptional()
	@IsEnum(PreferenceType)
	public reminderNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public trustedPersonNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public sharedWithMeNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public appUpdateNotificationPreference!: PreferenceType | null;
	@IsBoolean()
	public isOpgoAccessGranted!: boolean;
	@IsBoolean()
	public isAcpAccessGranted!: boolean;
	@IsBoolean()
	public isNodAccessGranted!: boolean;
}

export class UserSettingsDO {
	@IsOptional()
	@IsEnum(PreferenceType)
	public reminderNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public trustedPersonNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public sharedWithMeNotificationPreference!: PreferenceType | null;
	@IsOptional()
	@IsEnum(PreferenceType)
	public appUpdateNotificationPreference!: PreferenceType | null;
}

// this DO is used when retrieving information from myInfo
// will not be validated against in controller level
export class UserMyInfoDO {
	@IsOptional()
	@IsString()
	public name?: string;
	@IsOptional()
	@IsString()
	public uinfin?: string;
	@IsOptional()
	@IsString()
	public email?: string;
	@IsOptional()
	@IsString()
	public phone?: string;
	@IsOptional()
	@IsString()
	public dateOfBirth?: string;
	@IsOptional()
	public registeredAddress?: {
		postal: string | undefined;
		block: string | undefined;
		street: string | undefined;
		building: string | undefined;
		floor: string | undefined;
		unit: string | undefined;
	};
	@IsOptional()
	@IsString()
	public sex?: string;
	@IsOptional()
	@IsString()
	public residentialStatus?: string;
	@IsOptional()
	@IsString()
	public nationality?: string;
	@IsOptional()
	@IsString()
	public race?: string;
	@IsOptional()
	@IsString()
	public dialect?: string;
	@IsOptional()
	@IsString({ always: true })
	public lastRetrieved?: string;
	@IsOptional()
	public codes?: {
		nationality: string | undefined;
		residentialStatus: string | undefined;
		gender: string | undefined;
		race: string | undefined;
		dialect?: string;
	};
	@IsOptional()
	public housing?: {
		code: string | undefined;
		desc: string | undefined;
		type: string;
	};

	@IsOptional()
	public vehicles?: RegisteredVehicleDO[];
	@IsOptional()
	@IsString()
	public marital?: string;
	@IsOptional()
	@IsString()
	public passtype?: string;
}

export class RegisteredVehicleDO {
	@IsOptional()
	@IsString()
	public vehicleNo?: string;

	@IsOptional()
	@IsString()
	public iuLabelNo?: string;

	@IsOptional()
	public status?: {
		code?: string;
		desc?: string;
	};
}

export class ChangeACPPermissionDO {
	@IsString()
	public uinfin!: string;

	@IsBoolean()
	public isAcpAccessGranted!: boolean;
}
