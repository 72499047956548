import { IsEnum, IsNumber, IsString } from "class-validator";
import { AcpFormV3Status } from "../../enums";
import { ClassTransformers } from "@wog/mol-lib-api-contract/utils";
import { Transform } from "class-transformer";

export class AcpFormDO {
	@IsEnum(AcpFormV3Status)
	status!: AcpFormV3Status;

	@IsString()
	draftId!: string;

	@IsNumber()
	currentStep!: number;

	@Transform(ClassTransformers.ZonedDateTimeClassTransformer)
	createdAt!: string;
}
