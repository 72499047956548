export type ConfigCatKeys = {
	[key in FeatureConfigKeys]: boolean | number | string;
};

export type TContactDetailWhiteList = {
	email: string[];
	sms: string[];
};

export enum FeatureConfigKeys {
	ENABLED_ICA_MOCK_API = "ENABLED_ICA_MOCK_API",
	NOTIFICATION_BANNER_V2 = "NOTIFICATION_BANNER_V2",
	ICA_CIRIS_MAINTENANCE = "ICA_CIRIS_MAINTENANCE",
	SINGPASS_MAINTENANCE_TIME = "SINGPASS_MAINTENANCE_TIME",
	SINGPASS_DOWNTIME_ALERT_MESSAGE = "SINGPASS_DOWNTIME_ALERT_MESSAGE",
	BSG_API_SERVICE_ID = "BSG_API_SERVICE_ID",
	LPA_ACP_SURVEY_URL = "LPA_ACP_SURVEY_URL",
	SHOW_LATEST_NEWS = "SHOW_LATEST_NEWS",
	MOCK_LPA_STATUS_FROM_OPGO = "MOCK_LPA_STATUS_FROM_OPGO",
	MOCK_ACP_STATUS = "MOCK_ACP_STATUS",
	/**
	 * eWills module.
	 * TODO: remove when eWills goes live.
	 */
	EWILLS_TOGGLE = "EWILLS_TOGGLE",
	FIND_A_TOOL_TOGGLE = "FIND_A_TOOL_TOGGLE",
	DISABLE_EWILLS_SINGPASS_LOGIN = "DISABLE_EWILLS_SINGPASS_LOGIN",
	/**
	 * HDB Car parking module.
	 * TODO: remove when HDB car parking goes live.
	 */
	ENABLE_HDB_CAR_PARK = "ENABLE_HDB_CAR_PARKING",
	ENABLE_HDB_CAR_PARKING_ENTRY_POINT = "ENABLE_HDB_CAR_PARKING_ENTRY_POINT",
	HDB_CAR_PARK_EXCLUSION_LIST = "HDB_CAR_PARK_EXCLUSION_LIST",
	TOGGLE_MOCK_OS_SERVICE = "TOGGLE_MOCK_OS_SERVICE",
	HDB_PARKING_MOCK_API_RESULT = "HDB_PARKING_MOCK_API_RESULT",
	ENABLE_HDB_PARKING_MOCK_API = "ENABLE_HDB_PARKING_MOCK_API",
	ENABLED_SEND_MAIL_TO_TESTID = "ENABLED_SEND_MAIL_TO_TESTID",
	HDB_MAINTENANCE_TIME = "HDB_MAINTENANCE_TIME",
	DEFAULT_LOCAL_STORAGE_TTL = "DEFAULT_LOCAL_STORAGE_TTL",
	LAST_UPDATED_DATE = "LAST_UPDATED_DATE",
	DISABLE_ACP_3_0_PRE_FORM_STATUS_CHECK = "DISABLE_ACP_3_0_PRE_FORM_STATUS_CHECK",
	SHOW_MAINTENANCE_PAGE_V2 = "SHOW_MAINTENANCE_PAGE_V2",
	CONTACT_DETAIL_WHITELIST = "CONTACT_DETAIL_WHITELIST",
	MONTHLY_ACP_STATS_EMAIL_LIST = "MONTHLY_ACP_STATS_EMAIL_LIST",
	/**
	 * TODO: remove when NOD goes live.
	 */
	ENABLE_NOTICE_OF_DEATH = "ENABLE_NOTICE_OF_DEATH",
	/**
	 * Toggle for ACP 3.0 final solution
	 * TODO: remove when ACP 3.0 final solution goes live.
	 */
	ACP_3_0 = "ACP_3_0",
}

export const defaultFeatureConfigValues: ConfigCatKeys = {
	ENABLED_ICA_MOCK_API: false,
	NOTIFICATION_BANNER_V2: "",
	ICA_CIRIS_MAINTENANCE: "",
	SINGPASS_MAINTENANCE_TIME: "",
	SINGPASS_DOWNTIME_ALERT_MESSAGE: "",
	EWILLS_TOGGLE: false,
	BSG_API_SERVICE_ID: "",
	LPA_ACP_SURVEY_URL: "",
	SHOW_LATEST_NEWS: false,
	MOCK_LPA_STATUS_FROM_OPGO: "NO_MOCK",
	MOCK_ACP_STATUS: "NO_MOCK",
	FIND_A_TOOL_TOGGLE: false,
	DISABLE_EWILLS_SINGPASS_LOGIN: false,
	ENABLE_HDB_CAR_PARKING: false,
	ENABLE_HDB_CAR_PARKING_ENTRY_POINT: false,
	TOGGLE_MOCK_OS_SERVICE: false,
	HDB_PARKING_MOCK_API_RESULT: `{"NUM_BKNG":"ML-HDB-20230723-001","STATUS":"0","ERROR_CODE":"200","ERROR_MESSAGE":""}`,
	ENABLE_HDB_PARKING_MOCK_API: false,
	HDB_CAR_PARK_EXCLUSION_LIST: "",
	ENABLED_SEND_MAIL_TO_TESTID: false,
	HDB_MAINTENANCE_TIME: "{}",
	DEFAULT_LOCAL_STORAGE_TTL: 180 * 60 * 60 * 60,
	LAST_UPDATED_DATE: "2024-03-13",
	DISABLE_ACP_3_0_PRE_FORM_STATUS_CHECK: false,
	/* SHOW_MAINTENANCE_PAGE_V2 json format
	{
		"startDatetime": "2024-03-25T22:00:00",
		"endDatetime": "2024-03-25T23:00:00",
		"EN": "29 December 2021, Wednesday, 6:00pm",
		"ZH": "2021年12月29日，星期三，6:00pm恢复运作",
		"TA": "29 டிசம்பர் 2021 புதன்கிழமை அன்று, 6:00pm",
		"BM": "29 Disember 2021, Rabu, 6:00pm"
	}
	*/
	SHOW_MAINTENANCE_PAGE_V2: "",
	CONTACT_DETAIL_WHITELIST: "{}",
	MONTHLY_ACP_STATS_EMAIL_LIST: "{}",
	ENABLE_NOTICE_OF_DEATH: false,
	ACP_3_0: false,
};
