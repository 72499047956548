// [ACP cleanup]
import { IsObject } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.PUT;
export const url = "/api/v3/acp";

export class Body {
	// TODO: make a proper validator for this later
	@IsObject()
	draftContent!: object;
}

export class Response {}
